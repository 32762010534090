<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <b v-if="! isLegalType">ІПН:</b>
                <b v-else>ЄДРПОУ:</b>
                <span>{{insured.identification_number}} </span>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <b v-if="! isLegalType">ПІБ:</b>
                <b v-else>Компанія:</b>
                <span>{{insured.fio}} </span>
            </div>
        </div>
<!--        <div class="row mt-2">
            <div v-if="! isLegalType" class="col-md-12">
                <b>Тип документа:</b>
                <span>{{showInsurerDocument(insured, true)}} </span>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <b>Телефон:</b>
                <span>{{insured.phone}} </span>
            </div>
        </div>
        <div v-if="! isLegalType" class="row mt-2">
            <div class="col-md-12">
                <b>Номер та серія:</b>
                <span>{{insured.number}} {{insured.serial}} </span>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <b>Адреса:</b>
                <span>{{ (insured.cityName || '') +' , ' + (insured.street || '') + ' ' + (insured.house || '')  + ' ' + (insured.apartmentNumber || '')}} </span>
            </div>
        </div>-->
    </div>
</template>

<script>

export default {
    name: 'InsuredsListItem',
    props: ['insured', 'isLegalType'],
    methods: {
        showInsurerDocument(insured, getName = true) {
            let document =  this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { id: insured?.documentTypeId  });

            return (getName) ? document?.name : document?.id;
        },
    }
}

</script>
