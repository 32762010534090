<template>
    <div class="row">


        <div v-if="!typeDocument && ! isLegalType" class="col-12">
            <div v-if="!isTourism()" class="col-12">
                <h3>Заповніть один з документів</h3>
            </div>

            <select name="documentsList" class="custom-select documents-select mb-3"
                    :disabled="withoutIdentificationCode"
                    v-model="documentation.type">
                <option :value="documentItem.uid" v-for="documentItem in getAllowedDocumentsTypes()">{{ documentItem.name }}</option>
<!--                <option v-if="isAllowed('DOCUMENT_PASSPORT')" value="DOCUMENT_PASSPORT">Паспорт</option>-->
<!--                <option v-if="isAllowed('DOCUMENT_ID_CARD')" value="DOCUMENT_ID_CARD" >ID карта</option>-->
<!--                <option v-if="isAllowed('DOCUMENT_DRIVERS_LICENSE') && !checkIsIngo()" value="DOCUMENT_DRIVERS_LICENSE" >Водійські права</option>-->
<!--                <option v-if="isAllowed('DOCUMENT_EXTERNAL_PASSPORT') && checkIsEwa()" value="DOCUMENT_EXTERNAL_PASSPORT">Закордонний паспорт</option>-->
<!--                <option v-if="isAllowed('DOCUMENT_FOREIGN_PASSPORT') && checkIsEwa()" value="DOCUMENT_FOREIGN_PASSPORT">Іноземний паспорт</option>-->
<!--                <option v-if="isAllowed('DOCUMENT_RESIDENCE_PERMIT') && checkIsEwa()" value="DOCUMENT_RESIDENCE_PERMIT">Вид на проживання</option>-->
<!--                <option v-if="isAllowed('DOCUMENT_ID_RESIDENCE_PERMIT') && checkIsEwa()" value="DOCUMENT_ID_RESIDENCE_PERMIT">ID-вид на проживання</option>-->
            </select>
        </div>

        <div v-if="typeDocument && ! isLegalType" class="col-12">
            <div class="col-12">
                <h3>Заповніть документ</h3>
                <h5>{{ typeDocument }}</h5>
            </div>
        </div>




        <div v-if="! isLegalType && isCustomField() && ! customerHaveDocumentERetiree()" class="col-lg-6">
            <div class="form-group tooltip-group">
                <input v-mask="getMaskType()" v-model="documentation.serial" @change="dataChenged"
                       type="text" :placeholder="getPlaceholderSerial()+'*'"
                       :class="['form-control',{invalid: ((!$v.documentation.serial.required && submitCheck) || !$v.documentation.serial.minPassportSerial) }]">
                <i data-html="true" v-if="!typeDocument && ! isLegalType" data-placement="bottom" data-select="true"
                   data-toggle="tooltip" title="" class="icon question"
                   :data-original-title="getSeriesTitleHint()"></i>
                <small class="error"
                       v-if="!$v.documentation.serial.required && submitCheck">Введіть серію документа</small>
                <small class="error"
                       v-else-if="!$v.documentation.serial.minPassportSerial">
                    Введіть коректну серію документа
                </small>
            </div>
        </div>
        <!---->
        <div v-if="! isLegalType && isCustomField() && ! customerHaveDocumentERetiree()"  class="col-lg-6">
            <div class="form-group tooltip-group">
                <input v-mask="getMaskSerial()" type="text" placeholder="Номер*" @change="dataChenged"
                       v-model="documentation.number"
                       :class="['form-control',{invalid: ((!$v.documentation.number.required && submitCheck) || !$v.documentation.number.minPassportNumber || !$v.documentation.number.minLength)}]"
                >
                <i data-html="true" v-if="!typeDocument && ! isLegalType" data-placement="bottom"
                   data-toggle="tooltip" data-select="true" title=""
                   class="icon question"
                   :data-original-title="getNumberTitleHint()"></i>
                <small class="error"
                       v-if="!$v.documentation.number.required && submitCheck">
                    Введіть номер документа</small>
                <small class="error"
                       v-else-if="!$v.documentation.number.minLength">
                    Введіть коректно номер документа</small>
                <small class="error"
                       v-else-if="!$v.documentation.number.minPassportNumber && this.documentation.type === 'DOCUMENT_PASSPORT'">
                    Номер паспорта повинен складатися з 6 цифр
                </small>
                <small class="error"
                       v-else-if="!$v.documentation.number.minPassportNumber && this.documentation.type === 'DOCUMENT_ID_CARD'">
                    Номер id-картки повинен складатися з 9 цифр
                </small>
            </div>
        </div>
        <div v-if="! isLegalType && isCustomField() && ! customerHaveDocumentERetiree()"  class="col-lg-6">
            <div class="form-group tooltip-group">
                <input v-mask="'##.##.####'" @change="dataChenged"
                       v-model="documentation.dateOfIssue"
                       :placeholder="getPlaceholderDate()"
                       :class="['form-control', 'form-input_date', {invalid:
                                                                    (!$v.documentation.dateOfIssue.required && submitCheck) ||
                                                                     (!$v.documentation.dateOfIssue.customMinLength) ||
                                                                      (!$v.documentation.dateOfIssue.maxLength) ||
                                                                      (documentation.dateOfIssueError) ||
                                                                      (!$v.documentation.dateOfIssue.daysInMonth && submitCheck)}]">
                <i data-html="true" v-if="!typeDocument && ! isLegalType" data-placement="bottom"
                   data-toggle="tooltip" data-select="true" title=""
                   class="icon question"
                   :data-original-title="getDateTitleHint()"></i>
                <small class="error"
                       v-if="!$v.documentation.dateOfIssue.required && submitCheck">
                    Введіть дату видачі документа</small>
                <small class="error"
                       v-else-if="(!$v.documentation.dateOfIssue.customMinLength && submitCheck) || (!$v.documentation.dateOfIssue.maxLength && submitCheck) || (!$v.documentation.dateOfIssue.daysInMonth && submitCheck)">
                    Введіть коректно дату видачі документа</small>
                <small class="error"
                       v-else-if="documentation.dateOfIssueError && submitCheck">
                    На момент видачі документа вам має бути більше {{ isDocumentIdCard() ? '14 років' : '16 років' }}</small>
            </div>
        </div>

        <div v-if="! isLegalType && isCustomField() && ! customerHaveDocumentERetiree()"  class="col-lg-6">
            <div class="form-group tooltip-group">
<!--
                <vue-typeahead-bootstrap type="text" maxlength="200" v-model="documentation.issuedBy" @input="dataChenged"
                                         placeholder="Ким видан" :minMatchingChars="3"
                                         :data="getPassportCenters()"
                                         :class="['typeahead-inp',{invalid: (!$v.documentation.issuedBy.required && submitCheck) || (!$v.documentation.issuedBy.minLength)}]"/>
-->

              <input @change="dataChenged"
                     v-model="documentation.issuedBy"
                     placeholder="Ким видан"
                     :class="['form-control', 'form-input', {invalid: (!$v.documentation.issuedBy.required && submitCheck) || (!$v.documentation.issuedBy.minLength)}]">

                <i data-html="true" v-if="!typeDocument && ! isLegalType" data-placement="bottom"
                   data-toggle="tooltip" data-select="true" title=""
                   class="icon question"
                   :data-original-title="getIssuedTitleHint()"></i>
                <small class="error"
                       v-if="!$v.documentation.issuedBy.required && submitCheck">
                    Введіть ким виданий документ</small>
                <small class="error"
                       v-if="!$v.documentation.issuedBy.minLength">
                    Введіть коректно дані</small>
            </div>
        </div>

        <!-- Электронный льготник особый кейс start -->
        <div v-if="! isLegalType && isDocumentERetiree()" class="col-lg-6">
            <div class="form-group tooltip-group">
                <input v-mask="'MMMMMMMMMMMMMMMMMMMM'" v-model="documentation.number" @change="dataChenged"
                       type="text" placeholder="Номер*"
                       :class="['form-control',{invalid: (!$v.documentation.number.required && submitCheck)}]">
                <small class="error"
                       v-if="!$v.documentation.number.required && submitCheck">Введіть Номер документа</small>
            </div>
        </div>

        <div v-if="! isLegalType && isDocumentNeedEndDate()" class="col-lg-6">
            <div class="form-group tooltip-group">
                <input v-mask="'##.##.####'" @change="dataChenged"
                       v-model="documentation.endDateOfIssue"
                       placeholder="Закінчення дії*"
                       :class="['form-control', 'form-input_date', {invalid:
                                                                    (!$v.documentation.endDateOfIssue.required && submitCheck) ||
                                                                     (!$v.documentation.endDateOfIssue.customMinLength) ||
                                                                      (!$v.documentation.endDateOfIssue.maxLength)}]">
                <small class="error"
                       v-if="!$v.documentation.endDateOfIssue.required && submitCheck">
                    Введіть дату закінчення документа</small>
                <small class="error"
                       v-else-if="(!$v.documentation.endDateOfIssue.customMinLength && submitCheck) ||
                                                                          (!$v.documentation.endDateOfIssue.isSameOrAfter && submitCheck)">
                    Введіть дату закінчення документа</small>
            </div>
        </div>
        <!-- Электронный льготник особый кейс end  -->


    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import * as router from "vue-router";
import {customerMixin, companyMixin, documentMixin} from "../../../mixin";
import {email, maxLength, minLength, numeric, required, requiredIf} from "vuelidate/lib/validators";

export default {
    mixins: [customerMixin, documentMixin, companyMixin],
    components: {Multiselect,VueTypeaheadBootstrap},
    props: ['productType', 'offerInfo', 'searchInfo', 'documentation', 'submitCheck', 'withoutIdentificationCode', 'dateBirth'],

    data: function () {
        return {
            typeDocument: null,
            typeDocumentId: null,
        };
    },
    watch: {
        'documentation.type': async function( newValue, oldValue) {

            //console.log('newValue', newValue,  'oldValue', oldValue )
            if (this.isDocumentERetireeByUid(newValue)) {

                this.documentation.serial = 'АА';//
                this.documentation.issuedBy = null;
                this.documentation.dateOfIssue = null;
            }else if (!this.isDocumentNeedEndDate(newValue)) {
                this.documentation.endDateOfIssue = null;
            } else {
               // this.isCustomField = true;
            }
        },
    },

    created() {

        this.getAllowedDocumentsTypes();
        // if (this.searchInfo?.customerType == 2) {
        //     this.isLegalType = true
        // }

        let customerPrivilegeId = parseInt(this.customerPrivilege ?? 1); // TODO это не верно, потом вставлю ключ к хранилищю
       // console.log('customerPrivilegeId', customerPrivilegeId)
        // для ОСАГО при выборе льготник после калькуляции стает доступна только форма с выбраным видом документа льготника. остальные поля скрываються
        // (инвалид, льготник, електронный льготник, чернобылец, участник войны)

        if (this.isOsago() && customerPrivilegeId !== 1) {
            this.typeDocument = this.$_.findWhere(this.privilegeList, { id: customerPrivilegeId }).text;
            this.typeDocumentId = this.$_.findWhere(this.privilegeList, { id: customerPrivilegeId }).documentUid;
            this.documentation.type = this.typeDocumentId;
        }
    },

    computed: {
        availableDocuments() {
            //надо отсечь список доступных документов для ситуации если выбран льготный тип документа
            let available_documents =  this.offerInfo?.available_documents ?? this.offerInfo?.allowed_documents ?? null;
            let customerPrivilegeId = parseInt(this.customerPrivilege ?? 1); // TODO это не верно, потом вставлю ключ к хранилищю
            if (this.isOsago() && customerPrivilegeId !== 1) {
              this.typeDocument = this.$_.findWhere(this.privilegeList, { id: customerPrivilegeId }).text;
              this.typeDocumentId = this.$_.findWhere(this.privilegeList, { id: customerPrivilegeId }).documentUid;
              this.documentation.type = this.typeDocumentId;

              if (this.typeDocumentId) {
                const selectedPrivilege = this.privilegeList.find(privilege => privilege.documentUid === this.typeDocumentId);
                if (selectedPrivilege) {
                  const selectedDocument = selectedPrivilege.document;
                  if(available_documents){
                    available_documents = available_documents.filter(document => document === selectedDocument);
                    let infoOrder = this.$store.state.osagoStore.infoOrder;
                    infoOrder.offer.available_documents = available_documents;
                    this.$store.commit('SET_INFO_ORDER', infoOrder);
                    return available_documents;
                  }
                }
              }
            }
            return available_documents;
        },
        privilegeList() {
            return this.$store.getters.CUSTOMER_PRIVILEGE
        },
        customerPrivilege() {
            return this.offerInfo?.customerPrivilege ?? this.searchInfo.customerPrivilege ?? null;
        },
        isLegalType() {
            if (this.searchInfo?.customerType) {
                return this.searchInfo?.customerType == 2;
            } else if (this.searchInfo?.userType) {
                return this.searchInfo?.userType.id == 2;
            }
            return false;
        },
        allDocumentTypes () {
            return this.$store.getters.DOCUMENTS_TYPES
        },
        commonDocumentTypes () {
            return this.$store.getters.COMMON_DOCUMENTS_TYPES
        },
    },

    validations: {
        documentation: {
            serial: {required: requiredIf(function (nestedModel) {
                    return [1,3,5].some(data => data === Number(this.productType)) && ! this.isLegalType
                }),
                minPassportSerial(value) {
                    if (this.documentation.type == 1 && value &&  value.length < 2) {//'DOCUMENT_PASSPORT'
                        return false;
                    }else if(this.documentation.type == 3 && value &&  value.length < 14){ //'DOCUMENT_ID_CARD'
                        return false;
                    }

                    return true
                }
            },
            number: {required: requiredIf(function (nestedModel) {
                    return [1,3,5].some(data => data === Number(this.productType)) && ! this.isLegalType
                }),
                minLength: minLength(2),
                minPassportNumber(value) {
                    if (! this.customerHaveDocumentERetiree() && this.documentation.type == 1 && value &&  value.length < 6) {//'DOCUMENT_PASSPORT'
                        return false;
                    }else if (! this.customerHaveDocumentERetiree() && this.documentation.type == 3 && value &&  value.length < 9) {//'DOCUMENT_ID_CARD'
                        return false;
                    }

                    return true
                }
            },
            issuedBy: {required: requiredIf(function (nestedModel) {
                    return (! this.checkIsEwa() &&  ! this.isLegalType) || (this.checkIsEwa() && ! this.isDocumentPassport());
                }), minLength: minLength(2)},
            endDateOfIssue: { required: requiredIf(function (nestedModel) {
                    // пока сделаем обязательно только для электронного пенсионера DOCUMENT_E_RETIREE
                    return (this.isOsago() && this.customerHaveDocumentERetiree()) || this.isDocumentERetiree; //this.isDocumentNeedEndDate();
                }),
                maxLength: maxLength(10),
                isSameOrAfter(value) {
                    return   (this.isOsago() && ! this.customerHaveDocumentERetiree()) || moment(value, 'DD.MM.YYYY').isSameOrAfter(moment(), 'day');
                },
                customMinLength(value) {
                    return ! (value !== undefined && value !== null && value.length >= 1 && value.length < 10);
                },
            },
            dateOfIssue: {required: requiredIf(function (nestedModel) {
                    //TODO вывести это в миксин
                    if (this.checkIsEwa() && this.isDocumentPassport()) {
                        return false
                    }

                    if (this.dateBirth !== null && this.documentation.dateOfIssue !== null) {
                        let dateIssue = parseInt(moment(this.documentation.dateOfIssue, 'DD.MM.YYYY').diff(moment(), 'years')) * -1;
                        let dateBirth = parseInt(moment(this.dateBirth, 'DD.MM.YYYY').diff(moment(), 'years')) * -1;

                        if (this.isDocumentIdCard() && dateBirth - dateIssue < 14) {
                            this.documentation.dateOfIssueError = true;
                        }
                        if(dateBirth - dateIssue < 15){
                            this.documentation.dateOfIssueError = true;
                        }
                        else {
                            this.documentation.dateOfIssueError = false;
                        }
                    }
                    return ! this.isLegalType

                }), maxLength: maxLength(10),
                customMinLength(value) {
                    if (this.customerHaveDocumentERetiree() || (this.checkIsEwa() && ! value)) {
                        return  true;
                    }

                    return ! (value !== null && value.length >= 1 && value.length < 10);
                },
                daysInMonth(value) {

                    if (this.isLegalType) {
                        return true;
                    }

                    if (this.customerHaveDocumentERetiree() || ((this.checkIsCredo() || this.checkIsEwa()) && ! value)) {
                        return  true;
                    }
                    return moment.duration(moment().diff(moment(value, 'DD.MM.YYYY'))).asDays() >= 0;
                }
            },
        },
    },

    methods: {
        dataChenged(){
          return this.$emit('data-chenged');
        },
        getAllowedDocumentsTypes() {
            let transformedDocs = this.availableDocuments.map(docId => {
                const matchingItem = this.allDocumentTypes.find(item => item.id === docId);
                return {
                    id: matchingItem.id,
                    name: matchingItem.name,
                    uid: matchingItem.uid,
                };//matchingItem ? matchingItem : docId
            });

            let customerPrivilegeId = parseInt(this.customerPrivilege ?? 1);
            // отфильтровывать только для обычного customer без льгот
            if (this.isOsago() && customerPrivilegeId == 1) {
                transformedDocs = transformedDocs.filter(doc => {
                    if (this.commonDocumentTypes.find(item => item.id === doc.id)) {
                        return doc;
                    }
                });
            }
            return transformedDocs;
        },
        customerHasPrivilege() {
            let customerPrivilegeId = parseInt(this.offerInfo?.customerPrivilege ?? 1); // TODO это не верно, потом вставлю ключ к хранилищю
            return customerPrivilegeId !== 1;
        },
        isOsago() {
            return this.productType == 1;
        },
        isTourism() {
            return this.productType == 5;
        },
        checkIsEwa() {
            return this.isEwa(this.offerInfo?.moduleId);
        },
        checkIsCredo() {
            return this.isCredo(this.offerInfo?.moduleId);
        },
        checkIsIngo() {
            return this.orderData?.offer?.companyId == '01eh4rcv4g32t3g2bgpjkpjtqv' || this.orderData?.offer?.companyId == '01ewxf4hbg8ay1zdtanw6pvzn7';
        },
        isDocumentPassport() {
            return this.documentation.type == 1;//'DOCUMENT_PASSPORT'
        },
        isDocumentIdCard() {
            return this.documentation.type == 3;//'DOCUMENT_ID_CARD'
        },
        customerHaveDocumentERetiree() {
            return this.isDocumentERetiree(this.customerPrivilege);//this.offerInfo?.customerPrivilege
        },
        isDocumentERetiree() {
            return this.isDocumentERetireeByUid(this.documentation.type);
        },
        isDocumentNeedEndDate() {
            let itemType = this.getDocumentIdByUid(this.documentation.type);
            switch (itemType) {
                case "DOCUMENT_ID_CARD":
                    return true;
                case "DOCUMENT_E_RETIREE":
                    return true;
                case "DOCUMENT_ID_RESIDENCE_PERMIT":
                    return true;
                default:
                    return false;
            }
        },
        isCustomField() {
            if (this.documentation.type != 8) {//'DOCUMENT_E_RETIREE'
                return true;
            } else {
                return false;
            }
        },
        getMaskType() {
            if (this.typeDocument) {
                return "XXXX";
            }

            let itemType = this.getDocumentIdByUid(this.documentation.type);

            switch (itemType) {
                case "DOCUMENT_PASSPORT":
                    return "KK";
                case "DOCUMENT_ID_CARD":
                    return "########-#####";
                case "DOCUMENT_DRIVERS_LICENSE":
                  return 'MMM';
                case "DOCUMENT_EXTERNAL_PASSPORT":
                    return "NN";
                case "DOCUMENT_FOREIGN_PASSPORT":
                case "DOCUMENT_REGISTRATION_CARD":
                case "DOCUMENT_BIRTH_CERTIFICATE":
                    return "MMMM";
                case "DOCUMENT_RESIDENCE_PERMIT":
                    return "MMMM";
                case "DOCUMENT_ID_RESIDENCE_PERMIT":
                    return "MMMMMMMM-MMMMM";
                default:
                    return "XXXX";

            }
        },
        getMaskSerial() {
            if (this.typeDocument) {
                return "XXXXXXXXXXXXXXXXXXXX";
            }
            let itemType = this.getDocumentIdByUid(this.documentation.type);

            switch (itemType) {
                case "DOCUMENT_PASSPORT":
                    return "######";
                case "DOCUMENT_ID_CARD":
                    return "#########";
                case "DOCUMENT_DRIVERS_LICENSE":
                    return "######";
                case "DOCUMENT_EXTERNAL_PASSPORT":
                    return "######";
                case "DOCUMENT_FOREIGN_PASSPORT":
                    return "MMMMMMMMMMMMMMMMMMMM";
                case "DOCUMENT_RESIDENCE_PERMIT":
                    return "MMMMMMMMMMMMMMMMMMMM";
                default:
                    return "XXXXXXXXXXXXXXXXXXXX";
            }
        },
        getPlaceholderSerial() {
            if (this.documentation.type === 3 ||  //'DOCUMENT_ID_CARD'
                this.documentation.type === 12  //'DOCUMENT_ID_RESIDENCE_PERMIT'
            ) {
                return 'Запис';
            }

            return 'Серія';
        },
        getPlaceholderDate() {
            if (this.documentation.type === 11) { //'DOCUMENT_RESIDENCE_PERMIT'
                return 'Дата видачі';
            }

            return 'Дата видачі';
        },
        getNumberTitleHint() {
            let itemType = this.getDocumentIdByUid(this.documentation.type);
            switch (itemType) {
                case "DOCUMENT_DRIVERS_LICENSE":
                    return '<img src="/images/tooltips/driver/number.jpg">';
                case "DOCUMENT_ID_CARD":
                    return '<img src="/images/tooltips/card/number.png">';
                case "DOCUMENT_FOREIGN_PASSPORT":
                    return '<img src="/images/tooltips/international/number.jpg">';
                case "DOCUMENT_RESIDENCE_PERMIT":
                    return '<img src="/images/tooltips/residence/number.jpg">';
                default:
                    return '<img src="/images/tooltips/passport/number.jpg">';
            }
        },
        getSeriesTitleHint() {
            let itemType = this.getDocumentIdByUid(this.documentation.type);
            switch (itemType) {
                case "DOCUMENT_DRIVERS_LICENSE":
                    return '<img src="/images/tooltips/driver/code.jpg">';
                case "DOCUMENT_ID_CARD":
                    return '<img src="/images/tooltips/card/code.png">';
                case "DOCUMENT_FOREIGN_PASSPORT":
                    return '<img src="/images/tooltips/international/code.jpg">';
                case "DOCUMENT_RESIDENCE_PERMIT":
                    return '<img src="/images/tooltips/residence/code.jpg">';
                default:
                    return '<img src="/images/tooltips/passport/code.jpg">';
            }
        },
        getDateTitleHint() {
            let itemType = this.getDocumentIdByUid(this.documentation.type);
            switch (itemType) {
                case "DOCUMENT_DRIVERS_LICENSE":
                    return '<img src="/images/tooltips/driver/date.png">';
                case "DOCUMENT_ID_CARD":
                    return '<img src="/images/tooltips/card/date.png">';
                case "DOCUMENT_FOREIGN_PASSPORT":
                    return '<img src="/images/tooltips/international/date.jpg">';
                case "DOCUMENT_RESIDENCE_PERMIT":
                    return '<img src="/images/tooltips/residence/date.jpg">';
                default:
                    return '<img src="/images/tooltips/passport/date.jpg">';
            }
        },
        getIssuedTitleHint() {
            let itemType = this.getDocumentIdByUid(this.documentation.type);
            switch (itemType) {
                case "DOCUMENT_DRIVERS_LICENSE":
                    return '<img src="/images/tooltips/driver/issue.png">';
                case "DOCUMENT_ID_CARD":
                    return '<img src="/images/tooltips/card/issue.png">';
                case "DOCUMENT_FOREIGN_PASSPORT":
                    return '<img src="/images/tooltips/international/issue.jpg">';
                case "DOCUMENT_RESIDENCE_PERMIT":
                    return '<img src="/images/tooltips/residence/issue.jpg">';
                default:
                    return '<img src="/images/tooltips/passport/issue.jpg">';
            }
        },
        getPassportCenters() {
            let itemType = this.getDocumentIdByUid(this.documentation.type);

            switch (itemType) {//this.documentation.type
                case "DOCUMENT_PASSPORT":
                    return this.$store.getters.PASSPORT_CENTERS_LIST;
                case "DOCUMENT_ID_CARD":
                    return this.$store.getters.ID_CARD_CENTERS_LIST;
                case "DOCUMENT_DRIVERS_LICENSE":
                    return this.$store.getters.DRIVER_LICENSE_CENTERS_LIST;
                case "DOCUMENT_RETIREE":
                    return this.$store.getters.RETIREE_CENTERS_LIST;
                default:
                    return [];
            }
        },


        // choseСountry(value) {
        //     this.country = value;
        //     $(".tooltip").remove();
        //     $('.params-city-status').removeClass('disabled');
        // },
        // isInvalid(val) {
        //     return ((val == null || val.id == 0));
        // },
        // countrySelected () {
        //     this.countrySelect({
        //         country: this.country,
        //     })
        // },
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

