<template>
   <div class="modal modal-container modal-flight-success fade" id="modalFlightSuccess" tabindex="-1" role="dialog" aria-labelledby="modalFlightSuccess" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
         <div class="modal-content">
            <div class="modal-body">
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" fill="white"/>
                        <path d="M28 12L12 28" stroke="black" stroke-width="2"/>
                        <path d="M12 12L28 28" stroke="black" stroke-width="2"/>
                     </svg>
               </button>
               <div class="modal-flight-success__content">
                  <div class="modal-flight-success__heading">Чорнетка згенерована!</div>
                  <div :class="['modal-flight-succes__nav mfs-content-center', !showDraftUrlBtn]">
                     <a v-if="showDraftUrlBtn" class="btn copy-btn" @click="$emit('dataDraftUrl')">Роздрукувати</a>
                     <a class="btn copy-btn" @click="$emit('next')">Перейти до наступного кроку</a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   props: ['showDraftBtn'],
   data: function () {
      return {
         showDraftUrlBtn: true
      }
   },
   created() {
      this.showDraftUrlBtn = this.showDraftBtn;
   },

   mounted() {

   },

   methods: {

   }
}
</script>
